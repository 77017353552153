/**
 * CONTENTS
 *
 * VARIABLES
 * Colors........... All website colors
 * Themes........... Themes for clinic
 * Typography....... Font-family, font-size, line-height
 * Buttons.......... Buttons informations
 * Form............. Input, select style
 * Media Queries ... Breakpoint for responsive
 * Grid ............ Grid col and gutter
 */
/*------------------------------------*\
    #FONTS
\*------------------------------------*/
/*------------------------------------*\
    #COLORS
\*------------------------------------*/
/* Box Shadow */
/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    #FORMS
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA-QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #GRID
\*------------------------------------*/
/*
 * Unstyled list - remove margin, padding and list type
 */

.unstyled-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* Make hidden text accessible by screen readers */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Clearfix: contain floats
 */

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}


.clearfix:after {
  clear: both;
}

#body {
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
}

#body a, #body a:visited {
  color: #e3002b;
  text-decoration: none;
}

#body a:hover {
  color: #e3002b;
  text-decoration: underline;
}

#body h1, #body h2 {
  padding: 1rem 0;
}

#body h1:first-child, #body h2:first-child {
  margin-top: 0;
}

#body h1, #body h2, #body h3, #body h4, #body h5, #body h6 {
  font-family: 'Lato', sans-serif;
  color: #e3002b;
}

#body h1 i, #body h2 i, #body h3 i, #body h4 i, #body h5 i, #body h6 i {
  margin-right: .5rem;
}

#body p {
  text-align: justify;
}

#body #page-block {
  max-width: 100%;
  margin-top: 4rem;
  padding-bottom: 5rem;
}

#body .-success {
  color: #33D334;
}

#body .-error {
  color: #ff310c;
}

/**
 * CONTENTS
 *
 * VARIABLES
 * Colors........... All website colors
 * Themes........... Themes for clinic
 * Typography....... Font-family, font-size, line-height
 * Buttons.......... Buttons informations
 * Form............. Input, select style
 * Media Queries ... Breakpoint for responsive
 * Grid ............ Grid col and gutter
 */
/*------------------------------------*\
    #FONTS
\*------------------------------------*/
/*------------------------------------*\
    #COLORS
\*------------------------------------*/
/* Box Shadow */
/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    #FORMS
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA-QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #GRID
\*------------------------------------*/
/*
 * Unstyled list - remove margin, padding and list type
 */

.unstyled-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* Make hidden text accessible by screen readers */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Clearfix: contain floats
 */

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}


.clearfix:after {
  clear: both;
}

#body header {
  font-size: 18px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
}

#body header .navbar-brand {
  color: rgba(0, 0, 0, 0.87);
}

#body header .navbar-collapse .nav-item a {
  font-size: 15px;
}

#body header .navbar-collapse .nav-item a:first-letter {
  text-transform: capitalize;
}

#body header .navbar-collapse .nav-item .dropdown-menu {
  background-color: transparent;
}

#body header .navbar-collapse .nav-item .dropdown-menu .dropdown-item:focus, #body header .navbar-collapse .nav-item .dropdown-menu .dropdown-item:hover {
  background: none;
}

/**
 * CONTENTS
 *
 * VARIABLES
 * Colors........... All website colors
 * Themes........... Themes for clinic
 * Typography....... Font-family, font-size, line-height
 * Buttons.......... Buttons informations
 * Form............. Input, select style
 * Media Queries ... Breakpoint for responsive
 * Grid ............ Grid col and gutter
 */
/*------------------------------------*\
    #FONTS
\*------------------------------------*/
/*------------------------------------*\
    #COLORS
\*------------------------------------*/
/* Box Shadow */
/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    #FORMS
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA-QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #MEDIA QUERIES
\*------------------------------------*/
/*------------------------------------*\
    #GRID
\*------------------------------------*/
/*
 * Unstyled list - remove margin, padding and list type
 */

.unstyled-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* Make hidden text accessible by screen readers */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Clearfix: contain floats
 */

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}


.clearfix:after {
  clear: both;
}

footer {
  font-size: 13px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: #fff;
}

footer div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer div img {
  width: 100%;
  max-width: 22vw;
}

@media (min-width: 62em) {
  footer div img {
    max-width: 11vw;
  }
}
