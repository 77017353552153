@import "../utilities/variables";
#body {
  header {
    font-size: 18px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(#fff, 0.9);
    .navbar {
      &-brand {
        color: $text-color;
      }
      &-collapse {
        .nav-item {
          a {
            &:first-letter {
              text-transform: capitalize;
            }
            font-size: 15px;
          }
          .dropdown-menu {
            background-color: transparent;
            .dropdown-item {
              &:focus, &:hover {
                background: none;
              }
            }
          }
        }
      }
    }

  }
}
