@import "variables";
@import "sass-mq";


//normalize
//* {
//    box-sizing: border-box;
//}

#body {
    color: $text-color;
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    //font-weight: normal;

    a, a:visited {
        color: $c-primary;
        text-decoration: none;
    }
    a:hover {
        color: $c-primary;
        text-decoration: underline;
    }

    h1, h2 {
        padding: 1rem 0;
        &:first-child {
            margin-top: 0;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Lato', sans-serif;
        color: $c-primary;
        i {
            margin-right: .5rem;
        }
    }

    p {
        text-align: justify;
    }

    #page-block {
        max-width: 100%;
        margin-top: 4rem;
        padding-bottom: 5rem;
    }

    .-success {
        color: $c-check;
    }

    .-error {
        color: $c-error;
    }

    .source {

    }
}

