@import "../utilities/variables";
@import "../utilities/sass-mq";

footer {
  font-size: 13px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: #fff;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 100%;
      max-width: 22vw;
      @include mq($from: desktop) {
        max-width: 11vw;
      }
    }
  }
}